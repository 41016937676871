import React from 'react'

import useQuery from './useQuery'
import "./thankYouPage.modules.css"
import { GatsbyImage } from 'gatsby-plugin-image'

const VideoPlayer = () => {
    const data = useQuery()
    const { bannerSection, bannerBackgroundColor, videoUrl, backgroundColor } = data
    return (
        <div className="video-player-container" style={{ backgroundColor: backgroundColor }}>
            <div className="thankyou-container" style={{ backgroundColor: bannerBackgroundColor }}>
                <div>
                    <GatsbyImage image={bannerSection.image} className="thankyou-safety-image" alt="" />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                    <span className="thankyou-safety-title" style={{ color: bannerSection?.title?.color }}>{bannerSection?.title?.text}</span>
                    <span className="thankyou-safety-subtitle" style={{ color: bannerSection?.subtitle?.color }}>{bannerSection?.subtitle?.text}</span>
                </div>
            </div>
            <div className="video-container">
                <div className="video">
                    <video controls autoPlay className={"video-player"}>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}
export default VideoPlayer
