import { useStaticQuery, graphql } from "gatsby";

import { ImageWithTitleSubtitleProps, VideoPlayerSectionData } from "../../../types/types";

const processThankyouPageData = (data): VideoPlayerSectionData => {
  const myData = data.allWpPage.edges[0]?.node?.thankYouPage
  const bannerData: ImageWithTitleSubtitleProps = {
    title: myData?.titleSection?.titletext,
    subtitle: myData?.titleSection?.subtitletext,
    image: myData?.titleSection?.image?.localFile?.childImageSharp?.gatsbyImageData
  }
  const videoPlayerSectionData: VideoPlayerSectionData = {
    backgroundColor: myData?.background?.color ?? "#FFF",
    bannerBackgroundColor: myData?.titleSection?.background?.color,
    bannerSection: bannerData,
    videoUrl: myData?.videoSection?.videourl
  }
  return videoPlayerSectionData


}


const useQuery = () => {
  const data = processThankyouPageData(
    useStaticQuery(graphql`
        {
            allWpPage(filter: {uri: {eq: "/thank-you/"}}) {
              edges {
                node {
                  id
                  thankYouPage {
                    background {
                      color
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                    titleSection {
                      background {
                        color
                      }
                      image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                      subtitletext {
                        color
                        text
                      }
                      titletext {
                        color
                        text
                      }
                    }
                    videoSection {
                      videourl
                    }
                  }
                  title
                  uri
                }
              }
            }
          }
		`)
  );
  return data;
};

export default useQuery;

